(() => {
  const config = {
    rootSelector: '.profile-img-map',
    contentClassName: 'profile-img-map__content',
    activeContentModifier: 'active',
  } as const;

  const init = () => {
    const activeContentClassName = `${config.contentClassName}--${config.activeContentModifier}`;
    window.Bus.on('emu-image-map:areaClick', ({ id, isActive }) => {
      let marker: HTMLElement | null = null;
      let root: HTMLElement | null = null;
      let content: HTMLElement | null = null;
      if (
        typeof id !== 'string' ||
        typeof isActive !== 'boolean' ||
        !(marker = document.getElementById(id)) ||
        !(root = marker.closest(config.rootSelector)) ||
        !(content = root.querySelector(`.${config.contentClassName}`))
      ) {
        return;
      }
      if (isActive) {
        const computedStyle = window.getComputedStyle(marker);
        const bottomSpace = window.innerWidth < 1024 ? 6 : 0;
        content.style.top = `${
          Number.parseInt(computedStyle.top) +
          Number.parseInt(computedStyle.height) -
          bottomSpace
        }px`;
        content.style.left = `${
          Number.parseInt(computedStyle.left) +
          Number.parseInt(computedStyle.width) / 2
        }px`;
        content.classList.add(activeContentClassName);
      } else {
        content.classList.remove(activeContentClassName);
      }
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
