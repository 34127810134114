(() => {
  const config = {
    flipperSelector: '.flippable-card__flipper',
    flipSectionSelector: '.flippable-card__flip-section',
    flippedClassName: 'flipped',
  } as const;

  const findInstancesOf = <T extends HTMLElement>(
    selector,
    isInstanceOf: new (...args: Array<any>) => T,
    searchOn: HTMLElement | Document = document
  ): Array<T> => {
    const res: Array<T> = [];
    const elements = searchOn.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element instanceof isInstanceOf) {
        res.push(element);
      }
    }
    return res;
  };

  const init = () => {
    for (const flipBtn of findInstancesOf(
      config.flipperSelector,
      HTMLButtonElement
    )) {
      const flipSection = flipBtn.closest(config.flipSectionSelector);
      if (flipSection === null) {
        continue;
      }
      flipBtn.addEventListener('click', () => {
        flipSection.classList.toggle(config.flippedClassName);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
